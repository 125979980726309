import {get, post} from "./index"

/**
 * 保存租户名称展示
 * @param data
 * @returns {Promise<any>}
 */
export const saveTenant = data => {
    return post("/nameSetting/saveTenantName", data).then(res => res);
}

/**
 * 获取租户名称展示
 * @param data
 * @returns {Promise<unknown>}
 */
export const getTenant =()=> {
    return get("/nameSetting/getTenantName").then(res => res);
}
