<template>
    <div class="content-wrap">
        <div class="content-select">
            <div class="select-content">
                <el-form ref="tenantData" :model="tenantData" :rules="rules">
                    <el-row>
                        <el-col :span="7">
                            <el-form-item label="名称设置 " prop="nameSetting">
                                <el-input v-model="tenantData.nameSetting" placeholder="请输入名称"
                                    :disabled="!editMode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" @click="handleSave">
                                {{ saveBtnName }}
                            </el-button>
                            <el-button v-show="editMode" type="default" style="margin-left: 10px;"
                                       @click="handleCancel">取消
                            </el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import {saveTenant, getTenant} from "@/service/tenant";

    export default {
        name: "Email",
        data: function () {
            return {
                tenantData: {
                    nameSetting: ""
                },
                tenantDataCopy: {},
                rules: {
                   
                    nameSetting: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ]
                },
                editMode: false
            }
        },
        created() {
            this.getTenantProps();
        },
        computed: {
            saveBtnName() {
                return this.editMode ? '保存' : '编辑';
            }
        },
        methods: {
            handleSave() {
                if (this.editMode) {
                    this.submitForm();
                } else {
                    this.editMode = true;
                }
            },
            submitForm() {
                this.$refs["tenantData"].validate((valid) => {
                    if (valid) {
                        this.saveTenantData();
                    }
                });
            },
            /**
             * 保存名称
             * */
            async saveTenantData() {
                const res = await saveTenant(this.tenantData);
                console.log()
                if (res.success) {
                    this.editMode = false;
                    localStorage.removeItem("nameSetting")
                    localStorage.setItem("nameSetting",this.tenantData.nameSetting)
                    window.location.reload(); 
                }
            },
             /**
             * 获取邮件
             */
             async getTenantProps() {
                const res = await getTenant();
                if (res.success) {
                    if (res.data != null) {
                        this.$nextTick(() => {
                            this.tenantData = JSON.parse(JSON.stringify(res.data));
                            this.tenantDataCopy = res.data;
                        })

                    }
                }
            },
            /**
             * 取消重新刷新
             */
            handleCancel() {
                this.tenantData = this.tenantDataCopy;
                this.editMode = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content-select {
        display: flex;
        flex-direction: column;
        min-height: calc(100% + 20px);
    }

</style>